const Data = [
	{
		name: 'Tesla',
		color: '#e81c22',
		data: [
			{ year: '2015', quarter: '1', value: 10045 },
			{ year: '2015', quarter: '2', value: 11532 },
			{ year: '2015', quarter: '3', value: 11603 },
			{ year: '2015', quarter: '4', value: 17478 },
			{ year: '2016', quarter: '1', value: 14810 },
			{ year: '2016', quarter: '2', value: 14402 },
			{ year: '2016', quarter: '3', value: 24821 },
			{ year: '2016', quarter: '4', value: 22252 },
			{ year: '2017', quarter: '1', value: 25051 },
			{ year: '2017', quarter: '2', value: 22026 },
			{ year: '2017', quarter: '3', value: 26137 },
			{ year: '2017', quarter: '4', value: 29967 },
			{ year: '2018', quarter: '1', value: 29997 },
			{ year: '2018', quarter: '2', value: 40768 },
			{ year: '2018', quarter: '3', value: 83775 },
			{ year: '2018', quarter: '4', value: 90966 },
			{ year: '2019', quarter: '1', value: 63019 },
			{ year: '2019', quarter: '2', value: 95356 },
			{ year: '2019', quarter: '3', value: 97198 },
			{ year: '2019', quarter: '4', value: 112095 },
			{ year: '2020', quarter: '1', value: 88496 },
			{ year: '2020', quarter: '2', value: 90091 },
			{ year: '2020', quarter: '3', value: 139593 },
			{ year: '2020', quarter: '4', value: 180667 },
			{ year: '2021', quarter: '1', value: 184877 },
			{ year: '2021', quarter: '2', value: 201304 },
			{ year: '2021', quarter: '3', value: 241391 },
			{ year: '2021', quarter: '4', value: 308650 },
			{ year: '2022', quarter: '1', value: 310048 },
			{ year: '2022', quarter: '2', value: 254695 },
			{ year: '2022', quarter: '3', value: 343830 },
			{ year: '2022', quarter: '4', value: 405278 },
			{ year: '2023', quarter: '1', value: 422875 },
			{ year: '2023', quarter: '2', value: 466140 },
			{ year: '2023', quarter: '3', value: 435059 },
		]
	},
	{
		name: 'Volkswagen Group',
		color: '#318bd5',
		data: [
			{ year: '2015', quarter: '1', value: 273 },
			{ year: '2015', quarter: '2', value: 273 },
			{ year: '2015', quarter: '3', value: 273 },
			{ year: '2015', quarter: '4', value: 273 },
			{ year: '2016', quarter: '1', value: 215 },
			{ year: '2016', quarter: '2', value: 215 },
			{ year: '2016', quarter: '3', value: 215 },
			{ year: '2016', quarter: '4', value: 215 },
			{ year: '2017', quarter: '1', value: 756 },
			{ year: '2017', quarter: '2', value: 756 },
			{ year: '2017', quarter: '3', value: 756 },
			{ year: '2017', quarter: '4', value: 756 },
			{ year: '2018', quarter: '1', value: 6200 },
			{ year: '2018', quarter: '2', value: 6200 },
			{ year: '2018', quarter: '3', value: 6200 },
			{ year: '2018', quarter: '4', value: 6200 },
			{ year: '2019', quarter: '1', value: 18425 },
			{ year: '2019', quarter: '2', value: 18425 },
			{ year: '2019', quarter: '3', value: 18425 },
			{ year: '2019', quarter: '4', value: 18425 },
			{ year: '2020', quarter: '1', value: 33605 },
			{ year: '2020', quarter: '2', value: 30857 },
			{ year: '2020', quarter: '3', value: 58600 },
			{ year: '2020', quarter: '4', value: 108600 },
			{ year: '2021', quarter: '1', value: 59948 },
			{ year: '2021', quarter: '2', value: 110991 },
			{ year: '2021', quarter: '3', value: 122100 },
			{ year: '2021', quarter: '4', value: 159800 },
			{ year: '2022', quarter: '1', value: 99100 },
			{ year: '2022', quarter: '2', value: 118000 },
			{ year: '2022', quarter: '3', value: 75000 },
			{ year: '2022', quarter: '4', value: 205800 },
			{ year: '2023', quarter: '1', value: 141000 },
			{ year: '2023', quarter: '2', value: 180600 },
			{ year: '2023', quarter: '3', value: 149300 },
		]
	},
	{
		name: 'General Motors',
		color: '#8675db',
		data: [
			{ year: '2016', quarter: '4', value: 579 },
			{ year: '2017', quarter: '1', value: 3425 },
			{ year: '2017', quarter: '2', value: 4787 },
			{ year: '2017', quarter: '3', value: 7277 },
			{ year: '2017', quarter: '4', value: 9977 },
			{ year: '2018', quarter: '1', value: 4996 },
			{ year: '2018', quarter: '2', value: 7278 },
			{ year: '2018', quarter: '3', value: 6040 },
			{ year: '2018', quarter: '4', value: 6928 },
			{ year: '2019', quarter: '1', value: 5402 },
			{ year: '2019', quarter: '2', value: 6290 },
			{ year: '2019', quarter: '3', value: 6672 },
			{ year: '2019', quarter: '4', value: 6308 },
			{ year: '2020', quarter: '1', value: 7653 },
			{ year: '2020', quarter: '2', value: 3753 },
			{ year: '2020', quarter: '3', value: 5682 },
			{ year: '2020', quarter: '4', value: 8109 },
			{ year: '2021', quarter: '1', value: 10840 },
			{ year: '2021', quarter: '2', value: 13564 },
			{ year: '2021', quarter: '3', value: 4515 },
			{ year: '2021', quarter: '4', value: 26 },
			{ year: '2022', quarter: '1', value: 457 },
			{ year: '2022', quarter: '2', value: 7217 },
			{ year: '2022', quarter: '3', value: 15120 },
			{ year: '2022', quarter: '4', value: 16180 },
			{ year: '2023', quarter: '1', value: 21640 },
			{ year: '2023', quarter: '2', value: 15652 },
			{ year: '2023', quarter: '3', value: 20092 },
		]
	},
	{
		name: 'NIO',
		color: '#aba948',
		data: [
			{ year: '2018', quarter: '1', value: 0 },
			{ year: '2018', quarter: '2', value: 100 },
			{ year: '2018', quarter: '3', value: 3268 },
			{ year: '2018', quarter: '4', value: 7980 },
			{ year: '2019', quarter: '1', value: 3989 },
			{ year: '2019', quarter: '2', value: 3553 },
			{ year: '2019', quarter: '3', value: 4799 },
			{ year: '2019', quarter: '4', value: 8224 },
			{ year: '2020', quarter: '1', value: 3838 },
			{ year: '2020', quarter: '2', value: 10331 },
			{ year: '2020', quarter: '3', value: 12206 },
			{ year: '2020', quarter: '4', value: 17353 },
			{ year: '2021', quarter: '1', value: 20060 },
			{ year: '2021', quarter: '2', value: 21896 },
			{ year: '2021', quarter: '3', value: 24439 },
			{ year: '2021', quarter: '4', value: 25034 },
			{ year: '2022', quarter: '1', value: 25768 },
			{ year: '2022', quarter: '2', value: 25059 },
			{ year: '2022', quarter: '3', value: 31607 },
			{ year: '2022', quarter: '4', value: 40052 },
			{ year: '2023', quarter: '1', value: 31041 },
			{ year: '2023', quarter: '2', value: 23520 },
			{ year: '2023', quarter: '3', value: 55432 },
		]
	},
	{
		name: 'Ford Motor Company',
		color: '#a9581d',
		data: [
			{ year: '2020', quarter: '1', value: 106 },
			{ year: '2020', quarter: '2', value: 39 },
			{ year: '2020', quarter: '3', value: 162 },
			{ year: '2020', quarter: '4', value: 6410 },
			{ year: '2021', quarter: '1', value: 14816 },
			{ year: '2021', quarter: '2', value: 19963 },
			{ year: '2021', quarter: '3', value: 16437 },
			{ year: '2021', quarter: '4', value: 12467 },
			{ year: '2022', quarter: '1', value: 18152 },
			{ year: '2022', quarter: '2', value: 15801 },
			{ year: '2022', quarter: '3', value: 18257 },
			{ year: '2022', quarter: '4', value: 9365 },
			{ year: '2023', quarter: '1', value: 10866 },
			{ year: '2023', quarter: '2', value: 14843 },
			{ year: '2023', quarter: '3', value: 20962 },
		]
	},
	{
		name: 'Rivian',
		color: '#d59602',
		data: [
			{ year: '2021', quarter: '2', value: 0 },
			{ year: '2021', quarter: '3', value: 11 },
			{ year: '2021', quarter: '4', value: 909 },
			{ year: '2022', quarter: '1', value: 1227 },
			{ year: '2022', quarter: '2', value: 4467 },
			{ year: '2022', quarter: '3', value: 6584 },
			{ year: '2022', quarter: '4', value: 8054 },
			{ year: '2023', quarter: '1', value: 7946 },
			{ year: '2023', quarter: '2', value: 12640 },
			{ year: '2023', quarter: '3', value: 15564 },
		]
	},
	{
		name: 'Lucid Motors',
		color: '#db5da8',
		data: [
			{ year: '2021', quarter: '3', value: 0 },
			{ year: '2021', quarter: '4', value: 125 },
			{ year: '2022', quarter: '1', value: 360 },
			{ year: '2022', quarter: '2', value: 679 },
			{ year: '2022', quarter: '3', value: 1398 },
			{ year: '2022', quarter: '4', value: 1932 },
			{ year: '2023', quarter: '1', value: 1406 },
			{ year: '2023', quarter: '2', value: 1404 },
			{ year: '2023', quarter: '3', value: 1457 },
		]
	},
	{
		name: 'BYD Auto',
		color: '#48c34d',
		data: [
			{ year: '2018', quarter: '1', value: 5315 },
			{ year: '2018', quarter: '2', value: 18525 },
			{ year: '2018', quarter: '3', value: 30019 },
			{ year: '2018', quarter: '4', value: 49332 },
			{ year: '2019', quarter: '1', value: 45487 },
			{ year: '2019', quarter: '2', value: 50292 },
			{ year: '2019', quarter: '3', value: 27879 },
			{ year: '2019', quarter: '4', value: 23527 },
			{ year: '2020', quarter: '1', value: 18110 },
			{ year: '2020', quarter: '2', value: 28151 },
			{ year: '2020', quarter: '3', value: 32088 },
			{ year: '2020', quarter: '4', value: 52623 },
			{ year: '2021', quarter: '1', value: 38599 },
			{ year: '2021', quarter: '2', value: 54841 },
			{ year: '2021', quarter: '3', value: 91616 },
			{ year: '2021', quarter: '4', value: 135686 },
			{ year: '2022', quarter: '1', value: 140223 },
			{ year: '2022', quarter: '2', value: 180296 },
			{ year: '2022', quarter: '3', value: 258610 },
			{ year: '2022', quarter: '4', value: 329011 },
			{ year: '2023', quarter: '1', value: 264647 },
			{ year: '2023', quarter: '2', value: 352163 },
			{ year: '2023', quarter: '3', value: 431603 },
		]
	},
	{
		name: 'Volvo Group',
		color: '#56b4cb',
		data: [
			{ year: '2020', quarter: '1', value: 1164 },
			{ year: '2020', quarter: '2', value: 1164 },
			{ year: '2020', quarter: '3', value: 1164 },
			{ year: '2020', quarter: '4', value: 1164 },
			{ year: '2021', quarter: '1', value: 5141 },
			{ year: '2021', quarter: '2', value: 5141 },
			{ year: '2021', quarter: '3', value: 5141 },
			{ year: '2021', quarter: '4', value: 10303 },
			{ year: '2022', quarter: '1', value: 11721 },
			{ year: '2022', quarter: '2', value: 40108 },
			{ year: '2022', quarter: '3', value: 38582 },
			{ year: '2022', quarter: '4', value: 27864 },
		]
	},
	{
		name: 'Nissan Motor Company',
		color: '#b0469b',
		data: [
			{ year: '2015', quarter: '1', value: 10912 },
			{ year: '2015', quarter: '2', value: 10912 },
			{ year: '2015', quarter: '3', value: 10912 },
			{ year: '2015', quarter: '4', value: 10912 },
			{ year: '2016', quarter: '1', value: 12311 },
			{ year: '2016', quarter: '2', value: 12311 },
			{ year: '2016', quarter: '3', value: 12311 },
			{ year: '2016', quarter: '4', value: 12311 },
			{ year: '2017', quarter: '1', value: 11750 },
			{ year: '2017', quarter: '2', value: 11750 },
			{ year: '2017', quarter: '3', value: 11750 },
			{ year: '2017', quarter: '4', value: 11750 },
			{ year: '2018', quarter: '1', value: 21787 },
			{ year: '2018', quarter: '2', value: 21787 },
			{ year: '2018', quarter: '3', value: 21787 },
			{ year: '2018', quarter: '4', value: 21787 },
			{ year: '2019', quarter: '1', value: 10604 },
			{ year: '2019', quarter: '2', value: 11710 },
			{ year: '2019', quarter: '3', value: 11469 },
			{ year: '2019', quarter: '4', value: 11123 },
			{ year: '2020', quarter: '1', value: 6763 },
			{ year: '2020', quarter: '2', value: 5441 },
			{ year: '2020', quarter: '3', value: 6395 },
			{ year: '2020', quarter: '4', value: 8999 },
			{ year: '2021', quarter: '1', value: 7221 },
			{ year: '2021', quarter: '2', value: 9225 },
			{ year: '2021', quarter: '3', value: 6667 },
			{ year: '2021', quarter: '4', value: 8503 }
		]
	},
	{
		name: 'All Passenger Cars',
		color: '#ffffff',
		data: [
			{ year: '2015', quarter: '1', value: 15676400 },
			{ year: '2015', quarter: '2', value: 15676400 },
			{ year: '2015', quarter: '3', value: 15676400 },
			{ year: '2015', quarter: '4', value: 15676400 },
			{ year: '2016', quarter: '1', value: 16704400 },
			{ year: '2016', quarter: '2', value: 16704400 },
			{ year: '2016', quarter: '3', value: 16704400 },
			{ year: '2016', quarter: '4', value: 16704400 },
			{ year: '2017', quarter: '1', value: 17081300 },
			{ year: '2017', quarter: '2', value: 17081300 },
			{ year: '2017', quarter: '3', value: 17081300 },
			{ year: '2017', quarter: '4', value: 17081300 },
			{ year: '2018', quarter: '1', value: 17039500 },
			{ year: '2018', quarter: '2', value: 17039500 },
			{ year: '2018', quarter: '3', value: 17039500 },
			{ year: '2018', quarter: '4', value: 17039500 },
			{ year: '2019', quarter: '1', value: 16376100 },
			{ year: '2019', quarter: '2', value: 16376100 },
			{ year: '2019', quarter: '3', value: 16376100 },
			{ year: '2019', quarter: '4', value: 16376100 },
			{ year: '2020', quarter: '1', value: 14000200 },
			{ year: '2020', quarter: '2', value: 14000200 },
			{ year: '2020', quarter: '3', value: 14000200 },
			{ year: '2020', quarter: '4', value: 14000200 },
			{ year: '2021', quarter: '1', value: 14545200 },
			{ year: '2021', quarter: '2', value: 14545200 },
			{ year: '2021', quarter: '3', value: 14545200 },
			{ year: '2021', quarter: '4', value: 14545200 },
			{ year: '2022', quarter: '1', value: 14537600 },
			{ year: '2022', quarter: '2', value: 14537600 },
			{ year: '2022', quarter: '3', value: 14537600 },
			{ year: '2022', quarter: '4', value: 14537600 }
		]
	},
];

export default Data;
