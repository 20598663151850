import React, { Component } from 'react';
import TeslaModelPricingChart from './teslaModelPricingChart';
import CloseIcon from '../icons/close.svg';
import ShareTwitter from '../icons/ShareTwitter.js';
import ShareLink from '../icons/ShareLink.js';
import Info from '../icons/Info.js';


export default class TeslaModelPricingContainer extends Component {

	constructor() {
		super();
		this.state = {
			showInfoModal: false,
			chartActionColors: {
				ShareTwitter: 'var(--blue)',
				ShareLink: 'var(--blue)',
				Info: 'var(--blue)'
			}
		};

		this.toggleInfoModal = this.toggleInfoModal.bind(this);
	}

	handleCopyLinkClick = () => {
		navigator.clipboard.writeText(window.location.origin + '#tesla-vehicle-pricing');
		this.props.showToast();
		this.highlightChartActionButton('ShareLink');
	}

	getTwitterLink() {
		return `https://twitter.com/intent/tweet?url=${encodeURIComponent('https://bevcharts.com/#tesla-vehicle-pricing')}&text=${encodeURIComponent('Tesla\'s vehicle prices over time via @BEVcharts\n')}`;
	}

	toggleInfoModal = () => {
		this.setState({
			showInfoModal: !this.state.showInfoModal
		});
	}

	highlightChartActionButton(action) {

		const chartActionColors = {
			ShareTwitter: 'var(--blue)',
			ShareLink: 'var(--blue)',
			Info: 'var(--blue)'
		}
		chartActionColors[action] = 'var(--white)';
		this.setState({chartActionColors});

		setTimeout(() => {
			this.setState({
				chartActionColors: {
					ShareTwitter: 'var(--blue)',
					ShareLink: 'var(--blue)',
					Info: 'var(--blue)'
				}
			});
		}, 300);
	}

	render() {
		return <>
			{this.state.showInfoModal && <>
				<div className="modal-background" onClick={this.toggleInfoModal}></div>
				<div className="info-modal">
					<div className="close-button" onClick={this.toggleInfoModal}>
						<img src={CloseIcon} alt="Close Modal"/>
					</div>
					<div className="info-modal-content">
						<h2>Tesla Vehicle Prices (U.S.)</h2>
						<h3>Notes:</h3>
						<ul>
							<li>Data is from January 1 2021 through present day</li>
						</ul>

						<h3>Sources</h3>
						<ul>
							<li><a href="https://www.tesla.com" target="_blank" rel="noopener noreferrer">Tesla.com</a></li>
							<li><a href="https://www.youtube.com/watch?v=75ZDYUffdwI&t=106s" target="_blank" rel="noopener noreferrer">Tesla Daily</a></li>
						</ul>
					</div>
				</div></>
			}

			<div className="chart">
				<div className="chart-title" id="tesla-vehicle-pricing">
					<h1>
						Tesla Vehicle Prices (U.S.)
					</h1>
				</div>
				<div className="chart-container">
					<div className="chart-watermark">BEVcharts.com</div>
					<TeslaModelPricingChart isMobile={this.props.isMobile} />
				</div>
				<div className="chart-controls">
					<div className="left-space"></div>
					<div className="chart-actions">
						<div className="chart-action" onClick={this.toggleInfoModal}>
							<Info color={this.state.chartActionColors.Info} />
						</div>
						<div className="chart-action copy-link" alt="Copy Link" onClick={this.handleCopyLinkClick}>
							<ShareLink color={this.state.chartActionColors.ShareLink} />
						</div>
						<a href={this.getTwitterLink()} className="chart-action" target="_blank" rel="noopener noreferrer">
							<ShareTwitter color={this.state.chartActionColors.ShareTwitter} />
						</a>
					</div>
				</div>
			</div>
		</>
	}

}