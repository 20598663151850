import React, { Component } from 'react';
import SalesByOemChart from './salesByOemChart';
import CloseIcon from '../icons/close.svg';
import ShareTwitter from '../icons/ShareTwitter.js';
import ShareLink from '../icons/ShareLink.js';
import Info from '../icons/Info.js';


export default class SalesByOemContainer extends Component {

	constructor() {
		super();
		this.state = {
			showQuarterly: true,
			showYearly: false,
			showInfoModal: false,
			chartActionColors: {
				ShareTwitter: 'var(--blue)',
				ShareLink: 'var(--blue)',
				Info: 'var(--blue)'
			}
		};

		this.toggleInfoModal = this.toggleInfoModal.bind(this);
	}

	handleToggleGranularity = () => {
		this.setState({
			showQuarterly: !this.state.showQuarterly,
			showYearly: !this.state.showYearly
		});
	}

	handleCopyLinkClick = () => {
		navigator.clipboard.writeText(window.location.origin + '#sales-by-oem');
		this.props.showToast();
		this.highlightChartActionButton('ShareLink');
	}


	getTwitterLink() {
		return `https://twitter.com/intent/tweet?url=${encodeURIComponent('https://bevcharts.com/#sales-by-oem')}&text=${encodeURIComponent('Global Battery Electric Vehicle Sales/Deliveries by OEM via @BEVcharts\n')}`;
	}

	toggleInfoModal = () => {
		this.setState({
			showInfoModal: !this.state.showInfoModal
		});
	}

	highlightChartActionButton(action) {

		const chartActionColors = {
			ShareTwitter: 'var(--blue)',
			ShareLink: 'var(--blue)',
			Info: 'var(--blue)'
		}
		chartActionColors[action] = 'var(--white)';
		this.setState({chartActionColors});

		setTimeout(() => {
			this.setState({
				chartActionColors: {
					ShareTwitter: 'var(--blue)',
					ShareLink: 'var(--blue)',
					Info: 'var(--blue)'
				}
			});
		}, 300);
	}

	render() {
		return <>
			{this.state.showInfoModal && <>
				<div className="modal-background" onClick={this.toggleInfoModal}></div>
				<div className="info-modal">
					<div className="close-button" onClick={this.toggleInfoModal}>
						<img src={CloseIcon} alt="Close Modal"/>
					</div>
					<div className="info-modal-content">
						<h2>Global Battery Electric Vehicle Sales by OEM</h2>
						<h3>Notes:</h3>
						<ul>
							<li>Values are for only BEV sales (no PHEV or Hybrids)</li>
							<li>Ford's values are for production, not sales, of their BEVs due to limited available sales data</li>
							<li>Volkswagen's quarterly values for 2019 and earlier are averaged due to limited granularity of data for that year</li>
							<li>Volkswagen's e-Golf sales, represented in the chart, are only for Germany due to limited data</li>
							<li>Volvo's quarterly values are averaged due to limited granularity of data</li>
							<li>Nissan stopped publishing global Leaf sales after 2018.  Nissan's data for 2019+ includes only US, Canada, Japan, Norway, France</li>
							<li>Nissan's quarterly values are averaged due to limited granularity of data</li>
						</ul>

						<h3>Sources</h3>
						<ul>
							<li>Tesla: <a href="https://ir.tesla.com/#tab-quarterly-disclosure" target="_blank" rel="noopener noreferrer">Tesla IR Quarterly Shareholder Decks</a></li>
							<li>Volkswagen Group:&nbsp;
								<a href="https://www.volkswagenag.com/en/InvestorRelations/news-and-publications/Deliveries_to_customers.html" target="_blank" rel="noopener noreferrer">VW Monthly Delivery Reports</a>,&nbsp;
								<a href="https://de.wikipedia.org/wiki/VW_Golf_VII#e-Golf" target="_blank" rel="noopener noreferrer">e-Golf sales Germany</a>,&nbsp;
								<a href="https://www.autoblog.com/2019/11/27/volkswagen-electric-golf-sales-reach-100k/" target="_blank" rel="noopener noreferrer">e-Golf sales 2018</a>
							</li>
							<li>BYD Auto: <a href="http://www.byd.com/en/InvestorNotice.html?scroll=true" target="_blank" rel="noopener noreferrer">BYD IR Monthly Sales Reports</a></li>
							<li>Nissan Motor Company:&nbsp;
								<a href="https://en.wikipedia.org/wiki/Nissan_Leaf#Production_history" target="_blank" rel="noopener noreferrer">1</a>,&nbsp;
								<a href="https://www.goodcarbadcar.net/nissan-leaf-sales-figures/" target="_blank" rel="noopener noreferrer">2</a>,&nbsp;
								<a href="https://insideevs.com/news/560255/japan-nissan-leaf-sales-2021/" target="_blank" rel="noopener noreferrer">3</a>,&nbsp;
								<a href="https://insideevs.com/news/391146/2019-plugin-car-sales-norway-increased/#:~:text=BEV%20results%20already%20in%20our,LEAF%20%2D%20307%20and%206%2C127%20YTD" target="_blank" rel="noopener noreferrer">4</a>,&nbsp;
								<a href="https://insideevs.com/news/464304/norway-ev-sales-record-december-2020/" target="_blank" rel="noopener noreferrer">5</a>,&nbsp;
								<a href="https://insideevs.com/news/558447/norway-plugin-car-sales-december2021/" target="_blank" rel="noopener noreferrer">6</a>,&nbsp;
								<a href="https://insideevs.com/news/392288/2019-plugin-car-sales-france-record/#:~:text=Nissan%20LEAF%3A%20222%20and%203%2C738%20YTD" target="_blank" rel="noopener noreferrer">7</a>&nbsp;
							</li>
							<li>NIO: <a href="https://ir.nio.com/financials/quarterly-results" target="_blank" rel="noopener noreferrer">NIO IR Quarterly Results</a></li>
							<li>General Motors:&nbsp;
								<a href="https://gmauthority.com/blog/gm/chevrolet/bolt-ev/chevrolet-bolt-ev-sales-numbers/" target="_blank" rel="noopener noreferrer">Bolt EV Sales</a>,&nbsp;
								<a href="https://insideevs.com/news/558804/gm-delivered-26-evs-2021q4/" target="_blank" rel="noopener noreferrer">Hummer EV Sale</a>
							</li>
							<li>Ford Motor Company: <a href="https://shareholder.ford.com/investors/events-and-presentations/default.aspx" target="_blank" rel="noopener noreferrer">Ford IR Monthly Sales / Production Reports</a></li>
							<li>Rivian: <a href="https://rivian.com/investors" target="_blank" rel="noopener noreferrer">Rivian IR</a></li>
							<li>Volvo Group: <a href="https://www.media.volvocars.com/global/en-gb/media/pressreleases/293359/volvo-cars-records-full-year-sales-growth-sales-of-electrified-cars-grew-more-than-60?utm_campaign=Syndicated_293359&utm_medium=RSS_Corporate%20Specific%20Press%20Releases&utm_source=media.volvocars.com" target="_blank" rel="noopener noreferrer">Volvo Press Release</a></li>
							<li>Lucid Motors: <a href="https://ir.lucidmotors.com/" target="_blank" rel="noopener noreferrer">Lucid Motors IR</a></li>
							<li>All Passenger Vehicle Sales: <a href="https://www.best-selling-cars.com/international/2022-full-year-international-worldwide-car-sales/" target="_blank" rel="noopener noreferrer">best-selling-cars.com</a></li>
						</ul>
					</div>
				</div></>
			}

			<div className="chart">
				<div className="chart-title" id="sales-by-oem">
					<h1>
						Global Battery Electric Vehicle Sales by OEM
					</h1>
				</div>
				<div className="chart-container">
					<div className="chart-watermark">BEVcharts.com</div>
					<SalesByOemChart
						showQuarterly={this.state.showQuarterly}
						showYearly={this.state.showYearly}
						isMobile={this.props.isMobile}
					/>
				</div>
				<div className="chart-controls">
					<div className="toggle" onClick={this.handleToggleGranularity}>
						<div className={`toggle-button ${this.state.showYearly ? 'selected' : 'not-selected'}`}>
							Annual
						</div>
						<div className={`toggle-button ${this.state.showQuarterly ? 'selected' : 'not-selected'}`}>
							Quarterly
						</div>
					</div>
					<div className="chart-actions">
						<div className="chart-action" onClick={this.toggleInfoModal}>
							<Info color={this.state.chartActionColors.Info} />
						</div>
						<div className="chart-action copy-link" alt="Copy Link" onClick={this.handleCopyLinkClick}>
							<ShareLink color={this.state.chartActionColors.ShareLink} />
						</div>
						<a href={this.getTwitterLink()} className="chart-action" target="_blank" rel="noopener noreferrer">
							<ShareTwitter color={this.state.chartActionColors.ShareTwitter} />
						</a>
					</div>
				</div>
			</div>
		</>
	}

}