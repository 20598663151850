import React, { Component } from 'react';
import Data from '../data/salesByOem';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const LEGEND_ITEMS_PER_COLUMN = 6;
const DEFAULT_LINE_SIZE_DESKTOP = 3;
const DEFAULT_LINE_SIZE_MOBILE = 2;
const HIGHLIGHTED_LINE_EXTRA_SIZE = 3;
const TICK_FREQUENCY_QUARTERLY = 50000;
const TICK_FREQUENCY_YEARLY = 200000;
const TOTAL_BEV_LABEL = 'All BEVs';
const TOTAL_PASSENGER_CAR_LABEL = 'All Passenger Cars';

const volumeTickFormatter = (tick) => {
	if (tick === 0) {
		return '';
	} else if (tick >= 1000000) {
		return `${(tick / 1000000).toFixed(1)}M`;
	} else if (tick % 1000 === 0) {
		return `${tick / 1000}K`;
	} else {
		return '';
	}
};

const volumeTooltipFormatter = (value) => {
	// add commas to large numbers
	return value.toLocaleString();
}

export default class SalesByOemChart extends Component {

	constructor() {
		super();
		this.state = {
			oemList: [],
			quarterlyData: [],
			yearlyData: [],
			quarterlyDomainMax: 0,
			yearlyDomainMax: 0,
			lastYearWithData: 0
		};

		this.quarterTickFormatter = this.quarterTickFormatter.bind(this);
		this.yearTickFormatter = this.yearTickFormatter.bind(this);
	}

	componentDidMount() {
		const lineSize = this.props.isMobile ? DEFAULT_LINE_SIZE_MOBILE : DEFAULT_LINE_SIZE_DESKTOP;

		const oemList = [
			{
				name: TOTAL_BEV_LABEL,
				isEnabled: false,
				color: 'var(--white)',
				size: lineSize
			},
			...Data.map(oem => {return {
				name: oem.name,
				isEnabled: true,
				total: 0,
				color: oem.color,
				size: lineSize
			}})
		].sort((a, b) => { return a.name === TOTAL_PASSENGER_CAR_LABEL ? -1 : b.name === TOTAL_PASSENGER_CAR_LABEL ? 1 : 0; });
		oemList[0].isEnabled = false;

		const quarterlyData = [];
		Data.forEach(oem => {
			const oemName = oem.name;
			const oemData = oem.data;
			const oemRecord = oemList.find(o => o.name === oemName);
			oemData.forEach(quarterRecord => {
				const date = `${quarterRecord.year}-Q${quarterRecord.quarter}`;
				const quarterData = quarterlyData.find(q => q.date === date);
				if (quarterData) {
					quarterData[oemName] = quarterRecord.value;
				} else {
					quarterlyData.push({
						date,
						[oemName]: quarterRecord.value
					});
				}
				oemRecord.total += quarterRecord.value;
			});
		});
		quarterlyData.forEach(quarter => {
			const total = Object.keys(quarter).reduce((total, key) => {
				if (key !== 'date' && key !== TOTAL_PASSENGER_CAR_LABEL) {
					total += quarter[key];
				}
				return total;
			}, 0);
			quarter[TOTAL_BEV_LABEL] = total;
		});

		const yearlyData = [];
		Data.forEach(oem => {
			const oemName = oem.name;
			const oemData = oem.data;
			oemData.forEach(quarterRecord => {
				let date = `${quarterRecord.year}`;
				if (date === '2023') {
					date = '2023 YTD';
				}
				const yearData = yearlyData.find(q => q.date === date);
				if (yearData) {
					if (yearData[oemName]) {
						yearData[oemName] += quarterRecord.value;
					} else {
						yearData[oemName] = quarterRecord.value;
					}
				} else {
					yearlyData.push({
						date,
						[oemName]: quarterRecord.value
					});
				}
			});
		});
		yearlyData.forEach(year => {
			const total = Object.keys(year).reduce((total, key) => {
				if (key !== 'date' && key !== TOTAL_PASSENGER_CAR_LABEL) {
					total += year[key];
				}
				return total;
			}, 0);
			year[TOTAL_BEV_LABEL] = total;
			if (year.date > this.state.lastYearWithData) {
				this.setState({lastYearWithData: year.date});
			}
		});

		oemList.sort((a, b) => {
			return b.total - a.total;
		});

		const quarterlyDomainMax = this.getQuarterlyDomainMax(quarterlyData, oemList);
		const yearlyDomainMax = this.getYearlyDomainMax(yearlyData, oemList);

		this.setState({oemList, quarterlyData, yearlyData, quarterlyDomainMax, yearlyDomainMax});
	}

	getQuarterlyDomainMax(data, oemList) {
		data = data || this.state.quarterlyData;
		oemList = oemList || this.state.oemList;
		const activeOemKeys = oemList.filter(o => o.isEnabled).map(o => o.name);
		let quarterlyDomainMax = 0;
		data.forEach(quarter => {
			Object.keys(quarter).filter(key => activeOemKeys.includes(key)).forEach(key => {
				if (quarter[key] > quarterlyDomainMax) {
					quarterlyDomainMax = quarter[key];
				}
			});
		});

		const quarterlyDomainMaxRounded = Math.ceil(quarterlyDomainMax / TICK_FREQUENCY_QUARTERLY) * TICK_FREQUENCY_QUARTERLY;
		return quarterlyDomainMaxRounded;
	}

	getYearlyDomainMax(data, oemList) {
		data = data || this.state.yearlyData;
		oemList = oemList || this.state.oemList;
		const activeOemKeys = oemList.filter(o => o.isEnabled).map(o => o.name);
		let yearlyDomainMax = 0;
		data.forEach(year => {
			Object.keys(year).filter(key => activeOemKeys.includes(key)).forEach(key => {
				if (year[key] > yearlyDomainMax) {
					yearlyDomainMax = year[key];
				}
			});
		});

		const yearlyDomainMaxRounded = Math.ceil(yearlyDomainMax / TICK_FREQUENCY_YEARLY) * TICK_FREQUENCY_YEARLY;
		return yearlyDomainMaxRounded;
	}

	quarterTickFormatter(tick) {
		return this.props.isMobile ? '' : tick.split('-')[1];
	}

	yearTickFormatter(tickProps) {
		const { x, y, payload } = tickProps;
		const { value, offset } = payload;
		const quarter = value.split('-')[1];
		const year = value.split('-')[0];

		if (quarter === 'Q2') {
			if (this.props.isMobile) {
				return <text x={x * 1.037 - offset + 6} y={y + 6} textAnchor="middle" fill="#666666">{`${year}`}</text>;
			} else {
				return <text x={x * 1.03 - offset + 18} y={y + 4} textAnchor="middle" fill="#666666">{`${year}`}</text>;
			}
		}

		/*if (quarter === 'Q4' /&& year !== this.state.lastYearWithData*) {
			const pathX = (Math.floor(x) + 16) * 1.034; // changed 0 to 16 for some formatting bug with year dividers
			if (this.props.isMobile) {
				return <path d={`M${pathX},${y + 12}v${-30}`} stroke="var(--medium-gray)" />;
			} else {
				return <path d={`M${pathX},${y+8}v${-45}`} stroke="var(--medium-gray)" />;
			}
		}*/
		return null;
	}

	toggleOem(oemName) {
		const oemList = this.state.oemList.map(oem => {
			if (oem.name === oemName) {
				oem.isEnabled = !oem.isEnabled;
			}
			return oem;
		});

		const quarterlyDomainMax = this.getQuarterlyDomainMax(null, oemList);
		const yearlyDomainMax = this.getYearlyDomainMax(null, oemList);

		this.setState({oemList, quarterlyDomainMax, yearlyDomainMax});
	}

	toggleOemHighlight(oemName) {
		const oemList = this.state.oemList.map(oem => {
			if (oem.name === oemName) {
				if (this.props.isMobile) {
					oem.size = (oem.size === DEFAULT_LINE_SIZE_MOBILE) ? oem.size + HIGHLIGHTED_LINE_EXTRA_SIZE : DEFAULT_LINE_SIZE_MOBILE;
				} else {
					oem.size = (oem.size === DEFAULT_LINE_SIZE_DESKTOP) ? oem.size + HIGHLIGHTED_LINE_EXTRA_SIZE : DEFAULT_LINE_SIZE_DESKTOP;
				}
			}
			return oem;
		});
		this.setState({oemList});
	}

	getLegend()	{
		const legendColumns = [];

		let i = 0;
		while (i < this.state.oemList.length) {
			const legendItems = [];
			while (i < this.state.oemList.length && legendItems.length < LEGEND_ITEMS_PER_COLUMN) {
				const oem = this.state.oemList[i];
				legendItems.push(<div
					key={oem.name}
					className="legend-item"
					style={{color: oem.isEnabled ? oem.color : 'var(--light-gray)'}}
					onClick={() => this.toggleOem(oem.name)}
					onMouseOver={() => this.toggleOemHighlight(oem.name)}
					onMouseOut={() => this.toggleOemHighlight(oem.name)}
				>
					<div className="dot" style={{backgroundColor: oem.isEnabled ? oem.color : 'var(--light-gray)'}}></div>
					<span className={oem.isEnabled ? '' : 'strike'}>{oem.name}</span>
				</div>);
				i++;
			}
			legendColumns.push(<div key={i} className="legend-column">{legendItems}</div>);
		}

		return (<div className="legend-container">
			{legendColumns}
		</div>);
	}

	render() {

		const xAxisHeights = this.props.isMobile ? [10, 20, 30] : [30, 15, 45];

		return (<>
			{this.getLegend()}

			{/* Quarterly chart */}
			{this.props.showQuarterly &&
			<ResponsiveContainer width="100%" height="100%">
				<LineChart data={this.state.quarterlyData}>
					<CartesianGrid vertical={false} stroke={'var(--medium-dark-gray)'}/>
					<XAxis
						dataKey="date"
						tickFormatter={this.quarterTickFormatter}
						minTickGap={0}
						height={xAxisHeights[0]}
					/>
					<XAxis
						dataKey="date"
						axisLine={false}
						tickLine={false}
						interval={0}
						tick={this.yearTickFormatter}
						height={xAxisHeights[1]}
						scale="band"
						xAxisId="quarter"
					/>
					<YAxis
						tickCount={this.state.quarterlyDomainMax/TICK_FREQUENCY_QUARTERLY+1}
						domain={[0, this.state.quarterlyDomainMax]}
						tickFormatter={volumeTickFormatter}
						mirror={true}
					/>
					<Tooltip
						contentStyle={{backgroundColor: 'var(--black)', borderRadius: '15px', border: '2px solid var(--light-gray)'}}
						wrapperStyle={{zIndex: '1'}}
						labelStyle={{color: 'var(--white)', textAlign: 'center', fontWeight: 'bold', paddingBottom: '24px'}}
						formatter={volumeTooltipFormatter}
					/>
					{this.state.oemList.filter(oem => oem.isEnabled).map(oem =>
						<Line key={oem.name} dataKey={oem.name} type="monotoneX" stroke={oem.color} strokeWidth={oem.size} dot={false}/>
					)}
				</LineChart>
			</ResponsiveContainer>}

			{/* Yearly chart */}
			{this.props.showYearly &&
			<ResponsiveContainer width="100%" height="100%">
				<LineChart data={this.state.yearlyData}>
					<CartesianGrid vertical={false} stroke={'var(--medium-dark-gray)'}/>
					<XAxis dataKey="date" tick={true} height={xAxisHeights[2]} minTickGap={0}/>
					<YAxis
						tickCount={this.state.yearlyDomainMax/TICK_FREQUENCY_YEARLY+1}
						domain={[0, this.state.yearlyDomainMax]}
						tickFormatter={volumeTickFormatter}
						mirror={true}
					/>
					<Tooltip
						contentStyle={{backgroundColor: 'var(--black)', borderRadius: '15px', border: '2px solid var(--light-gray)'}}
						wrapperStyle={{zIndex: '1'}}
						labelStyle={{color: 'var(--white)', textAlign: 'center', fontWeight: 'bold', paddingBottom: '24px'}}
						formatter={volumeTooltipFormatter}
					/>
					{this.state.oemList.filter(oem => oem.isEnabled).map(oem =>
						<Line key={oem.name} dataKey={oem.name} type="natural" stroke={oem.color} strokeWidth={oem.size} dot={false}/>
					)}
				</LineChart>
			</ResponsiveContainer>}

		</>);
	}

}