import React, { Component } from 'react';
import './App.css';
import DownIcon from './icons/down.svg';
import Logo from './images/logo-large.svg';
import Twitter from './images/twitter-main.svg';
import SalesByOemContainer from './charts/salesByOemContainer';
import TeslaModelPricingContainer from './charts/teslaModelPricingContainer';

const VIEWPORT_WIDTH_THRESHOLD = 600;


class App extends Component {

	constructor(props) {
		super(props);
		const { innerWidth: width } = window;
		this.state = {
			isMobile: width < VIEWPORT_WIDTH_THRESHOLD,
			showToast: false,
			toastText: ''
		};
	}

	showToast() {
		this.setState({
			showToast: true,
			toastText: 'Link Copied!'
		});
		setTimeout(() => {
			this.setState({
				showToast: false
			});
		}, 2000);
	}

	render() {
		return (
			<div className="app">

				<div className="toast" style={{ visibility : this.state.showToast ? 'visible' : 'hidden', opacity: this.state.showToast ? 1 : 0 }}>
					<div className="toast-text">{this.state.toastText}</div>
				</div>

				<div className="header">
					<div className="header-logos">
						<div className="header-left">
						</div>
						<div className="header-center">
							<img src={Logo} alt="BEV Charts Logo" />
						</div>
						<div className="header-right">
							<a href="https://twitter.com/bevcharts" target="_blank" rel="noopener noreferrer">
							<img src={Twitter} alt="Twitter Logo" />
							</a>
						</div>
					</div>
					<div className="header-text">
						<p><strong>Battery Electric Vehicles (BEVs)</strong> are starting to see widespread adoption, similar to the typical adoption pattern for new technologies known as the "Adoption S-Curve." This website aims to provide data that demonstrates the growing market share of BEVs. The first chart we have published shows which original equipment manufacturers (OEMs) are leading the charge in the adoption of BEVs.  In the second chart, we look at vehicle price trends for the largest BEV manufacturer, Tesla.</p>
					</div>
				</div>

				<SalesByOemContainer isMobile={this.state.isMobile} showToast={this.showToast.bind(this)} />

				<TeslaModelPricingContainer isMobile={this.state.isMobile} showToast={this.showToast.bind(this)} />

				<div className="footer">
					<div className="down-button">
						<img src={DownIcon} alt="More"/>
						<p>More Charts<br/>Coming Soon</p>
						<img src={DownIcon} alt="More"/>
					</div>
				</div>
			</div>
		);
	}
}

export default App;