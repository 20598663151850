import React, { Component } from 'react';
import Data from '../data/teslaPricing';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const LEGEND_ITEMS_PER_COLUMN = 4;
const DEFAULT_LINE_SIZE_DESKTOP = 3;
const DEFAULT_LINE_SIZE_MOBILE = 2;

const priceFormatter = (tick) => {
	return '$' + tick.toLocaleString();
};

const dateTickFormatter = (tick) => {
	const d = new Date(tick);
	return `${d.getMonth() + 1}.${d.getDate()}.${d.getFullYear()}`;
};


export default class TeslaModelPricingChart extends Component {

	constructor() {
		super();
		this.state = {
			optionList: [],
			models: [],
			data: []
		};
	}

	componentDidMount() {
		const lineSize = this.props.isMobile ? DEFAULT_LINE_SIZE_MOBILE : DEFAULT_LINE_SIZE_DESKTOP;

		const optionList = [];
		const models = [];
		const data = [];

		Data.teslaModelPricing.forEach(record => {
			const date = record.Date;
			const model = this.formatModel(record.Sort);
			const price = record.Price;
			const dateRecord = data.find(o => o.date === date);
			if (!dateRecord) {
				data.push({
					date: date,
					epoch: new Date(date).getTime(),
					[model]: price
				});
			} else {
				dateRecord[model] = price;
			}

			let modelName = model.slice(0, 7);
			if (!optionList.find(o => o.name === model)) {
				optionList.push({
					name: model,
					model: modelName,
					isEnabled: true,
					color: null,
					size: lineSize
				});
			}

			if (!models.find(m => m.name === modelName)) {
				models.push({
					name: modelName,
					color: null,
					size: lineSize,
					isEnabled: true
				});
			}
		});

		models.forEach((model, index) => {
			model.color = "hsl(" + (index * (360 / models.length) % 360) + ",100%,50%)"
		});

		optionList.forEach((model, index) => {
			//model.color = "hsl(" + (index * (360 / optionList.length) % 360) + ",100%,50%)"
			model.color = models.find(m => m.name === model.model).color;
		});

		const modelOrder = ['X', 'S', 'Y', '3'];
		optionList.sort((a, b) => {
			if (a.name.charAt(6) === b.name.charAt(6)) {
				return data[data.length-1][a.name] - data[data.length-1][b.name];
			}
			return modelOrder.indexOf(a.name.charAt(6)) - modelOrder.indexOf(b.name.charAt(6));
		});
		models.sort((a, b) => {
			if (a.name.charAt(6) === b.name.charAt(6)) {
				return data[data.length-1][a.name] - data[data.length-1][b.name];
			}
			return modelOrder.indexOf(a.name.charAt(6)) - modelOrder.indexOf(b.name.charAt(6));
		});

		data.sort((a, b) => {
			return new Date(a.date) - new Date(b.date);
		});

		this.setState({optionList, models, data});
	}

	formatModel(input) {
		let result = '';
		let splitInput = input.split('-');
		const model = splitInput[2];
		let formattedModel = model.slice(0, model.length - 1) + ' ' + model.charAt(model.length - 1).toUpperCase();
		formattedModel = formattedModel.charAt(0).toUpperCase() + formattedModel.slice(1);
		const trim = splitInput.slice(3).join('-');
		result = trim.charAt(0).toUpperCase() + trim.slice(1);
		if (!result.includes(formattedModel)) {
			result = formattedModel + ' ' + result;
		}
		result = result.replace('All-Wheel Drive', 'AWD');
		result = result.replace('Rear-Wheel Drive', 'AWD');
		result = result.replace('Long Range', 'LR');
		result = result.replace('Standard Range Plus', 'SR+');
		return result;
	}

	getLegend()	{
		const legendColumns = [];

		let i = 0;
		while (i < this.state.models.length) {
			const legendItems = [];
			while (i < this.state.models.length && legendItems.length < LEGEND_ITEMS_PER_COLUMN) {
				const oem = this.state.models[i];
				legendItems.push(<div
					key={oem.name}
					className="legend-item"
					style={{color: oem.color}}
				>
					<div className="dot" style={{backgroundColor: oem.color}}></div>
					<span className={oem.isEnabled ? '' : 'strike'}>{oem.name}</span>
				</div>);
				i++;
			}
			legendColumns.push(<div key={i} className="legend-column">{legendItems}</div>);
		}

		return (<div className="legend-container">
			{legendColumns}
		</div>);
	}

	render() {

		//const xAxisHeights = this.props.isMobile ? [10, 20, 30] : [30, 15, 45];

		return (<>
			{this.getLegend()}
			{this.state.data.length &&
			<ResponsiveContainer width="100%" height="100%">
				<LineChart data={this.state.data}>
					<CartesianGrid vertical={false} stroke={'var(--medium-dark-gray)'}/>
					<XAxis dataKey="epoch" tickCount={10} minTickGap={0} angle={-30} tickSize={20} mirror={true} height={100} scale="time" type="number" domain={[this.state.data[0].epoch, this.state.data[this.state.data.length - 1].epoch]} tickFormatter={dateTickFormatter}/>
					<YAxis mirror={true} ticks={[25000, 50000, 75000, 100000, 125000, 150000]} domain={[0, 'dataMax']} tickFormatter={priceFormatter}/>
					{<Tooltip
						contentStyle={{backgroundColor: 'var(--black)', borderRadius: '15px', border: '2px solid var(--light-gray)'}}
						wrapperStyle={{zIndex: '1'}}
						labelStyle={{color: 'var(--white)', textAlign: 'center', fontWeight: 'bold', paddingBottom: '24px'}}
						formatter={priceFormatter}
						labelFormatter={dateTickFormatter}

					/>}
					{this.state.optionList.map((model, index) =>
						<Line key={model.name} dataKey={model.name} type="linear" stroke={model.color} strokeWidth={model.size} dot={false} />
					)}
				</LineChart>
			</ResponsiveContainer>}

		</>);
	}

}